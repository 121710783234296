/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const reservationListAsAdmin = `query ReservationListAsAdmin($type: String, $username: String!) {
  reservationListAsAdmin(type: $type, username: $username) {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
export const reservationList = `query ReservationList($type: String!) {
  reservationList(type: $type) {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
export const reservation = `query Reservation($reservationId: Int, $otaId: String) {
  reservation(reservationId: $reservationId, otaId: $otaId) {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
export const roomsListAsAdmin = `query RoomsListAsAdmin($username: String!) {
  roomsListAsAdmin(username: $username) {
    roomId
    state
    number
    sector
    type
  }
}
`;
export const roomsList = `query RoomsList {
  roomsList {
    roomId
    state
    number
    sector
    type
  }
}
`;
export const getSession = `query GetSession {
  getSession
}
`;
export const getSessionAsAdmin = `query GetSessionAsAdmin($username: String!) {
  getSessionAsAdmin(username: $username)
}
`;
export const buscarxdni = `query Buscarxdni($dni: String) {
  buscarxdni(dni: $dni)
}
`;
