import { ingreso } from "../../graphql/queries";
import moment from 'moment'
const defaultState={listaIngresos:[],ingreso:null,mobile:false,modal:false};
export const MejorarIngreso=(x)=>{
    return {...x,precioxnoche:x.precio,
        parcelas:x.parcelas.map(y=>({parcela:y})),tieneCheckout:x.checkout!="0000-00-00 00:00:00",
        deudor:x.checkout=="0000-00-00 00:00:00"&&moment(x.fechaSalida).add(3, 'hours').diff(moment())<=0}
}
export default function reducer(state=defaultState,{type,payload}){
    if (type=="LISTA_INGRESOS"){
        const payloadAux=payload.map(x=>{
            return MejorarIngreso(x)
        })
        
        return {...state,listaIngresos:payloadAux}
    }
    switch (type){
        case "MODAL_CAJA":
            return {...state,modal:payload};
        case "SET_MOBILE":
            return {...state,mobile:payload};
        case "SET_INGRESO":
            return {...state,ingreso:payload==null?null:payload};
        default:
            return {...state};
    }
}