import moment from 'moment'
const defaultState={
    listaReservas:[],
    reserva:{},
    fecha:null,
    listaDeptos:[],
    listaPrecios:[],
    divisa:0,
    informacionHotel:{},
    refrescarDispoYPrecios:0,
    isMobile:false};

export const ArreglarServicesList=(servicesList)=>{
servicesList=servicesList.map(y=>({...y,paymentsList:y.paymentsList?y.paymentsList:[]}));

servicesList=servicesList.map(x=>{
    return ({...x,
    perNight:Math.round(x.cost/x.quantity),
    paid:x.paymentsList.filter(x=>!x.isRefund).reduce((a,b,i)=>a+b.amount,0),
    due:x.cost-x.paymentsList.filter(x=>!x.isRefund).reduce((a,b,i)=>a+b.amount,0)+x.paymentsList.filter(x=>x.isRefund).reduce((a,b,i)=>a+b.amount,0)})})
    return servicesList
}

export const GetPercentPayed=(servicesList)=>{
            //Calcular porcentaje pagado
            let percentPayed=0
            let costoTotal=servicesList.length!=0?servicesList.map(x=>x.cost).reduce((a,b)=>a+b):0;
            let pagado=servicesList.length!=0?servicesList.map(x=>x.paymentsList.map(y=>y.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b):0;
            percentPayed=pagado/costoTotal;
            percentPayed=Math.round(percentPayed*100);
            if (costoTotal==0) {
                percentPayed=100;
            }
            //Si no hay nada pagado que diga que no pago nada
            if (servicesList.length==0){
                percentPayed=0;
            }
            if (percentPayed>100) {
                percentPayed=100;
            }
            return percentPayed;
}

export default function reducer(state=defaultState,{type,payload}){
    if  (type=="LISTA_RESERVAS"){
        if  (!payload){
            payload=state.listaReservas;
        }
        const payloadAux=payload.map(ingreso=>{
           /* const nochesPagas=ingreso.listaPagos.map(x=>x.noches).reduce((a,b) => a + b, 0);
            const fechaSalidaPaga=moment(ingreso.fechaIngreso).add(nochesPagas,"days").format("YYYY-MM-DD")*/
            const nights=moment(ingreso.checkoutEstimated).diff(ingreso.checkinEstimated,"days");
            const customersList=ingreso.customersList.map(x=>({...x,
                age:x.birthdate?moment().diff(x.birthdate,"years"):null}))
            var servicesList=ArreglarServicesList(ingreso.servicesList)
            const roomsList=ingreso.roomsList.map(y=>{
                if (y.roomId){
                    return state.listaDeptos.find(x=>x.roomId==y.roomId)
                }
                return state.listaDeptos.find(x=>x.roomId==y)
            })


            const percentPayed=GetPercentPayed(servicesList);
          //  const nochesFaltanPagar=moment(ingreso.fechaSalida).diff(fechaSalidaPaga,"days");
            return {...ingreso,
                nights,
                customersList,
                servicesList,
                roomsList,
                percentPayed
               /* precioxnoche:Math.round(ingreso.precio/nochesEstadia),
                fechaSalidaPaga,
                nochesPagas,
                nochesFaltanPagar,
                fechaIngreso:moment((ingreso.fechaIngreso)).add(3,"hours").format("YYYY-MM-DD"),
                fechaSalida:moment((ingreso.fechaSalida)).add(3,"hours").format("YYYY-MM-DD")*/}
        })
    
        return {...state,listaReservas:payloadAux};
    }

    switch (type){
        case "INFORMACION_HOTEL":
            return {...state,informacionHotel:payload};
        case "SET_RESERVA":
            return {...state,reserva:payload};
        case "SET_MODAL_RESERVA":
            return {...state,modal:payload};
        case "SET_FECHA":
            return {...state,fecha:payload};
        case "ROOM_TYPE":
            return {...state,roomType:payload};
        case "SET_PRECIOS":
            return {...state,precios:payload};
        case "SET_LISTA_DEPTOS":
            return {...state,listaDeptos:payload};
        case "SET_IS_MOBILE":
            return {...state,isMobile:payload};
        case "SET_DIVISA":
            return {...state,divisa:payload};
        case "SET_REFRESCAR":
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        case "SET_SESSION":
            return {...state,sesion:payload};
        case "SET_LISTA_PRECIOS":
            return {...state,listaPrecios:payload};
        case "ADD_SERVICE":
            const {reservationId,service}=payload;
            for(var reserva of state.listaReservas){
               
                if (reserva.id==reservationId){
                    reserva.servicesList=[...reserva.servicesList,service]
                }
            }
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        case "ADD_PAYMENT":
            const {serviceId,payment}=payload;
            for(var reserva of state.listaReservas){
                const service=reserva.servicesList.find(x=>x.serviceId==serviceId);
                if (service){
                   
                    service.paymentsList=[...service.paymentsList,payment]
                    reserva.servicesList=ArreglarServicesList(reserva.servicesList);
                    reserva.percentPayed=GetPercentPayed(reserva.servicesList);
                    console.log(reserva)
                }
            }
         
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        default:
            return {...state};
    }
}